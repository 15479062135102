import type { ComponentType } from "react"
import { useState, useEffect } from "react"

export function withScheduleCallButton(Component): ComponentType {
    return (props) => {
        const onClick = (e) => {
            console.log("clicked schedule call hero button")
            window?.posthog?.capture("Clicked Schedule Call", {
                placement: "button",
            })
            props.onClick && props.onClick(e)
        }
        return (
            <Component
                {...props}
                data-event-id="schedule-call-button"
                onClick={onClick}
            />
        )
    }
}

export function withScheduleCallTopbar(Component): ComponentType {
    return (props) => {
        const onClick = (e) => {
            console.log("clicked schedule call topbar")
            window?.posthog?.capture("Clicked Schedule Call", {
                placement: "topbar",
            })
            props.onClick && props.onClick(e)
        }
        return (
            <Component
                {...props}
                data-event-id="schedule-call-topbar"
                onClick={onClick}
            />
        )
    }
}

export function toggleVisibilityOnUrlParams(Component): ComponentType {
    return (props) => {
        const [showCalendly, setShowCalendly] = useState(false)
        useEffect(() => {
            const params = new URLSearchParams(window.location.search)
            // let showCalendly = false
            const experiment = params.get("experiment")
            const expvar = params.get("expvar")
            // console.log(`experiment=${experiment}; expvar=${expvar}`)

            if (experiment === "calendly" && expvar === "true") {
                // showCalendly = true
                if (showCalendly) {
                    console.log("already been here, returning")
                    return
                }
                setShowCalendly(true)
                console.log("running useEffect")
                // window?.posthog.onFeatureFlags(() => {
                // console.log("setting feature flags")
                // manually set feature flags based on the url param
                // window?.posthog?.onFeatureFlags(function () {
                // console.log("setting person properties for flags")
                // window?.posthog?.setPersonPropertiesForFlags({
                //     dcom_with_free_15min_call: true,
                // })
                // })

                // })
            }
        }, [])

        if (!showCalendly) {
            return <div />
        }
        return <Component {...props} />
    }
}

export function withToggleVisibility(Component): ComponentType {
    return (props) => {
        const [shouldRender, setShouldRender] = useState(false)

        useEffect(() => {
            window?.posthog?.onFeatureFlags(function () {
                if (
                    window?.posthog?.isFeatureEnabled(
                        "dcom_with_free_15min_call"
                    )
                ) {
                    setShouldRender(true)
                }
            })
        }, [])

        return shouldRender ? <Component {...props} /> : null
    }
}

export function withReverseToggleVisibility(Component): ComponentType {
    return (props) => {
        const [shouldRender, setShouldRender] = useState(false)

        useEffect(() => {
            window?.posthog?.onFeatureFlags(function () {
                if (
                    !window?.posthog?.isFeatureEnabled(
                        "dcom_with_free_15min_call"
                    )
                ) {
                    setShouldRender(true)
                }
            })
        }, [])

        return shouldRender ? <Component {...props} /> : null
    }
}
